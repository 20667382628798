import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, isRef as _isRef, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/close.png'
import _imports_1 from '@/assets/images/rule-content.png'


const _hoisted_1 = { class: "popup" }
const _hoisted_2 = { class: "container" }

import router from '@/router';
import { closeToast, showLoadingToast, showToast } from 'vant';
import { getStorage } from '@/utils/local'
import { ref, onMounted, shallowRef, getCurrentInstance, nextTick, proxyRefs, useAttrs, reactive } from 'vue'


/****************组件、js等引入*************/

export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  setup(__props, { expose: __expose }) {

const { proxy } = (getCurrentInstance() as any)


/****************渲染dom数据*************/
let show = shallowRef(false)
/****************渲染dom数据*************/
/***************将组件内给父组件的值暴露出去*****************/
__expose({ show })
/***************将组件内给父组件的值暴露出去*****************/
/****************函数*************/
let openShow =()=>{
    show.value = true
}
/****************函数*************/

/****************生命周期*************/
// createApp(()=>{
//     console.log("created")
// })
onMounted(async () => {
})
  /****************生命周期*************/

return (_ctx: any,_cache: any) => {
  const _component_van_popup = _resolveComponent("van-popup")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_van_popup, {
      show: _unref(show),
      "onUpdate:show": _cache[1] || (_cache[1] = ($event: any) => (_isRef(show) ? (show).value = $event : show = $event)),
      "close-on-click-overlay": false
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("img", {
            src: _imports_0,
            class: "close",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_isRef(show) //@ts-ignore
 ? show.value = false : show = false))
          }),
          _cache[2] || (_cache[2] = _createElementVNode("div", { class: "title" }, "活动规则", -1)),
          _cache[3] || (_cache[3] = _createElementVNode("div", { class: "rule-container" }, [
            _createElementVNode("img", {
              src: _imports_1,
              class: "rule-content"
            })
          ], -1))
        ])
      ]),
      _: 1
    }, 8, ["show"])
  ]))
}
}

})