import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, isRef as _isRef, vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "wrapper"
}

import router from '@/router';
import { useRoute } from 'vue-router'
import { closeToast, showLoadingToast, showToast } from 'vant';
import { getStorage } from '@/utils/local'
import { ref, onMounted, shallowRef, getCurrentInstance, nextTick, proxyRefs, useAttrs, reactive  } from 'vue'


/****************组件、js等引入*************/
interface QueryParam  {
  aid: string;
  activityTaskId: string,
  token: string
}

export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  setup(__props) {

const { proxy } = (getCurrentInstance() as any)


/****************渲染dom数据*************/
let prizeList = ref([])
let aid = ref("")
const queryParam = ref<QueryParam>({aid:'', activityTaskId:'', token: ''});
/****************渲染dom数据*************/


/****************函数*************/
let go = () => {
    
    if (!aid.value) {
        return
    }
    proxy.$removeStorage("jwt")
    proxy.$removeStorage("mobile")
    proxy.$removeStorage("AID")
    proxy.$setStorage("AID", aid.value)
    proxy.$router.replace("/home?aid=" +  aid.value)
}
/****************函数*************/

/****************生命周期*************/
onMounted(async () => {
    //缓存 setStorage方法调用示例
    // proxy.$setStorage('TEXT_DATA', { test: 1 })
    // console.log(proxy.$getStorage('TEXT_DATA'));
    // nextTick(() => {
    //     dataHandle()
    //     // if (guideRef.value) {
    //     //     guideRef.value.show = true
    //     // }
    // })
    //调用方法
    // 获取路由的查询参数
    queryParam.value = useRoute().query as { aid: string, activityTaskId: string, token: string };
    proxy.$setStorage("activityTaskId", queryParam.value.activityTaskId||'')
    proxy.$setStorage("token", queryParam.value.token||'')
    if (queryParam.value.aid!=undefined) {
        aid.value = queryParam.value.aid
        go()
    }
    //首页
    nextTick(() => {
    })
    // showToast('提示内容');
    //请求接口示例
    // let res = await proxy.$api.getBannerDetails({ partitionType: 1 })
    // console.log(res);

})
/****************生命周期*************/

return (_ctx: any,_cache: any) => {
  return (!queryParam.value.aid)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _cache[2] || (_cache[2] = _createTextVNode(" 活动ID：")),
        _withDirectives(_createElementVNode("input", {
          type: "text",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(aid) ? (aid).value = $event : aid = $event))
        }, null, 512), [
          [_vModelText, _unref(aid)]
        ]),
        _createElementVNode("button", {
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_unref(go) && _unref(go)(...args)))
        }, "跳转")
      ]))
    : _createCommentVNode("", true)
}
}

})