import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, isRef as _isRef, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "popup" }

import router from '@/router';
import { closeToast, showLoadingToast, showToast } from 'vant';
import { getStorage } from '@/utils/local'
import { ref, onMounted, shallowRef, getCurrentInstance, nextTick, proxyRefs, useAttrs, reactive } from 'vue'


/****************组件、js等引入*************/
interface Props {
  type: any,//1提示 2遗憾 3中奖
  popPic:string,
  popTxt:string
  //?:表示可选属性；:表示必选属性
}
//props默认值

export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  props: {
    type: { default: 3 },
    popPic: { default: "" },
    popTxt: { default: "" }
  },
  emits: ['methods'],
  setup(__props: any, { expose: __expose, emit: __emit }) {

const { proxy } = (getCurrentInstance() as any)


/****************渲染dom数据*************/
let show = ref(false)
/****************渲染dom数据*************/
/***************将组件内给父组件的值暴露出去*****************/
__expose({ show })
/***************将组件内给父组件的值暴露出去*****************/
/****************参数props*************/
// const props = defineProps({})//非ts环境
const props = __props
/****************参数props*************/
/****************函数*************/
let goOpen =()=>{
    window.location.href = 'https://www.abchina.com/web2native|channel=wallet_home*menu=wallet_home*step=02*param=1110'
    show.value = false
}
const emits = __emit
let jumpPrize =()=>{
    emits('methods')
    proxy.$router.push('/prize')
}
let verify = () =>{
    show.value = false
    emits('methods')
}
/****************函数*************/

/****************生命周期*************/
// createApp(()=>{
//     console.log("created")
// })
onMounted(async () => {
})
  /****************生命周期*************/


return (_ctx: any,_cache: any) => {
  const _component_van_popup = _resolveComponent("van-popup")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_van_popup, {
      show: _unref(show),
      "onUpdate:show": _cache[0] || (_cache[0] = ($event: any) => (_isRef(show) ? (show).value = $event : show = $event)),
      "close-on-click-overlay": false
    }, {
      default: _withCtx(() => _cache[1] || (_cache[1] = [
        _createElementVNode("div", { class: "container" }, null, -1)
      ])),
      _: 1
    }, 8, ["show"])
  ]))
}
}

})