const baseURL = process.env.VUE_APP_API_URL;

const REST = {
  login: '/api/user/codeLogin',
  info: '/api/activity/info',
  reserveState: '/api/activity/reserveState',
  reserve:'/api/activity/reserve',
  accountState:'/api/user/digitalAccountState',
  reserveHistory:'/api/activity/reserveHistory',
  prizeList:'/api/activity/userPrizerList',
  prizeClaim:'/api/activity/wxPrizeClaim',
  mobile:'/api/user/mobile',
  userState:'/api/user/state',
  drawInform:'/api/activity/drawInform',
  read:'/api/activity/read',
  winnerList:'/api/activity/listOfWinners',
  prizeRead:'/api/activity/drawRead',
  reserveRead:'/api/activity/reserveRead',
  userInfo:'/api/activity/userInfo',
  lottery:"/api/activity/lottery",
  missionList:"/api/activity/taskList",
  browse:"/api/activity/taskBrowse",
  taskShare:"/api/activity/taskShare"
}
import { Service } from "./request";
import qs from "qs";

export default {
  //用户登录
  getLogin(data: any) {
    return Service({
      url: baseURL + REST.login,
      method: "post",
      data: data,
    });
  },
  //活动信息
  getActivityInfo(data: any) {
    return Service({
      url: baseURL + REST.info,
      method: "get",
      params: data,
    });
  },
  //预约状态
  getReserveState(data: any) {
    return Service({
      url: baseURL + REST.reserveState,
      method: "get",
      params: data,
    });
  },
  //活动预约
  activityReserve(data: any) {
    return Service({
      url: baseURL + REST.reserve,
      method: "post",
      data: data,
    });
  },
  //查询数币状态
  getAccountState(data:any){
    return Service({
      url: baseURL + REST.accountState,
      method: "get",
      params: data,
    });
  },
  //预约历史
  getReserveHistory(data:any){
    return Service({
      url: baseURL + REST.reserveHistory,
      method: "get",
      params: data,
    });
  },
  //我的奖励
  getPrizeList(data:any){
    return Service({
      url: baseURL + REST.prizeList,
      method: "get",
      params: data,
    });
  },
  //领取奖品
  getPrizeClaim(data:any){
    return Service({
      url: baseURL + REST.prizeClaim,
      method: "post",
      params: data,
    });
  },
  //设置手机号
  setMobile(data:any){
    return Service({
      url: baseURL + REST.mobile,
      method: "post",
      data: data,
    });
  },
  //查询用户状态
  getUserState(data:any){
    return Service({
      url: baseURL + REST.userState,
      method: "get",
      params: data,
    });
  },
  //开奖通知
  getDrawInform(data:any){
    return Service({
      url: baseURL + REST.drawInform,
      method: "get",
      params: data,
    });
  },
  //已读
  getRead(data:any){
    return Service({
      url: baseURL + REST.read,
      method: "post",
      data: data,
    });
  },
  //中奖公示
  getWinnerList(data:any){
    return Service({
      url: baseURL + REST.winnerList,
      method: "get",
      data: data,
    })
  },
  //开奖已读
  prizeRead(data:any){
    return Service({
      url: baseURL + REST.prizeRead,
      method: "post",
      data: data,
    })
  },
  //预约已读
  reserveRead(data:any){
    return Service({
      url: baseURL + REST.prizeRead,
      method: "post",
      data: data,
    })
  },
  //已用户数币账户状态
  getUserInfo(data:any){
    return Service({
      url: baseURL + REST.userInfo,
      method: "get",
      data: data,
    })
  },
  //通用抽奖
  drawLottery(data:any){
    return Service({
      url:baseURL + REST.lottery,
      method:"post",
      data:data
    })
  },
  //获取任务列表
  getMissionList(data:any){
    return Service({
      url:baseURL + REST.missionList,
      method:"get",
      data:data
    })
  },
  //浏览任务
  taskBrowse(data:any){
    return Service({
      url:baseURL + REST.browse,
      method:"get",
      params:data
    })
  },

  //分享任务
  postTaskShare(data:any){
    return Service({
      url:baseURL + REST.taskShare,
      method:"post",
      data
    })
  }
}