import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, isRef as _isRef, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/close.png'


const _hoisted_1 = { class: "popup" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "content" }
const _hoisted_4 = { class: "u-flex u-ai-c u-jc-sb" }

import router from '@/router';
import { closeToast, showLoadingToast, showToast } from 'vant';
import { getStorage } from '@/utils/local'
import ClipboardJS from 'clipboard'
import { ref, onMounted, shallowRef, getCurrentInstance, nextTick, proxyRefs, useAttrs, reactive } from 'vue'


/****************组件、js等引入*************/
interface Props {
  describe:string,
  cardNo:string,
  cardCode:string,
  extData:string
  //?:表示可选属性；:表示必选属性
}
//props默认值

export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  props: {
    describe: { default: "" },
    cardNo: { default: "" },
    cardCode: { default: "" },
    extData: { default: "" }
  },
  emits: ['methods','prizeRead','reserveRead','goExchange'],
  setup(__props: any, { expose: __expose, emit: __emit }) {

const { proxy } = (getCurrentInstance() as any)


/****************渲染dom数据*************/
let show = ref(false)
/****************渲染dom数据*************/
/***************将组件内给父组件的值暴露出去*****************/
__expose({ show })
/***************将组件内给父组件的值暴露出去*****************/
/****************参数props*************/
// const props = defineProps({})//非ts环境
const props = __props
/****************参数props*************/
/****************函数*************/
const emits = __emit
let goOpen =()=>{
    window.location.href = 'https://www.abchina.com/web2native|channel=wallet_home*menu=wallet_home*step=02*param=1110'
    show.value = false
}
let goExchange =()=>{
    console.log("extData",props.extData)
    show.value = false
    window.location.href = props.extData
}
/****************函数*************/

/****************生命周期*************/
// createApp(()=>{
//     console.log("created")
// })
onMounted(async () => {
    let that = this
    const clipboard = new ClipboardJS(".copy",{
        text:function(){
            return props.cardNo + ';' + props.cardCode
        }
    })
    clipboard.on("success",function(e){
        console.log("复制",props.cardNo + ';' + props.cardCode)
        showToast("复制成功")
    })
})
  /****************生命周期*************/


return (_ctx: any,_cache: any) => {
  const _component_van_popup = _resolveComponent("van-popup")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_van_popup, {
      show: _unref(show),
      "onUpdate:show": _cache[2] || (_cache[2] = ($event: any) => (_isRef(show) ? (show).value = $event : show = $event)),
      "close-on-click-overlay": false
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("img", {
            src: _imports_0,
            class: "close",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_isRef(show) //@ts-ignore
 ? show.value = false : show = false))
          }),
          _cache[4] || (_cache[4] = _createElementVNode("div", { class: "title" }, " 温馨提示 ", -1)),
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.describe), 1),
          _createElementVNode("div", _hoisted_4, [
            _cache[3] || (_cache[3] = _createElementVNode("div", { class: "btn copy" }, "复制卡号/卡密", -1)),
            _createElementVNode("div", {
              class: "btn exchange",
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_unref(goExchange) && _unref(goExchange)(...args)))
            }, "去兑换")
          ])
        ])
      ]),
      _: 1
    }, 8, ["show"])
  ]))
}
}

})