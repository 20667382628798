import { createRouter, createWebHashHistory, RouteRecordRaw,createWebHistory } from 'vue-router'
import Example from '../views/Example/index.vue'
import beforeEach from './beforeEach'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/index'
  },
  {
    path: '/404',
    name: 'page404',   //必填
    meta: { name: 'page404' },
    component: () => import('../views/404/index.vue')
  },
  {
    path: '/home',
    name: 'home',   //必填
    meta: { name: 'home' },
    component: () => import('../views/home/index.vue')
  },{
    path: '/history',
    name: 'history',   //必填
    meta: { name: 'history' },
    component: () => import('../views/history/index.vue')
  },{
    path: '/prize',
    name: 'prize',   //必填
    meta: { name: 'prize' },
    component: () => import('../views/prize/index.vue')
  },{
    path: '/index',
    name: 'index',   //必填
    meta: { name: 'index' },
    component: () => import('../views/index/index.vue')
  },{
    path: '/publicty',
    name: 'publicty',   //必填
    meta: { name: 'publicty' },
    component: () => import('../views/publicty/index.vue')
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})
/**
 * 路由守卫
 */
router.beforeEach((guard) => {
  beforeEach.checkAuth(guard, router)
})

router.afterEach((to, from) => {
  document.body.scrollTop = document.documentElement.scrollTop = 0;
  // @ts-ignore
  // aplus_queue.push({
  //   action: 'aplus.sendPV',
  //   arguments: [{
  //     is_auto: false
  //   }, {
  //   }]
  // })
})

export default router
