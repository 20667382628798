import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, isRef as _isRef, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/images/close2.png'


const _hoisted_1 = { class: "popup" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "content" }
const _hoisted_4 = { class: "name" }
const _hoisted_5 = { class: "describe" }
const _hoisted_6 = ["src", "onClick"]
const _hoisted_7 = ["onClick", "src"]
const _hoisted_8 = { class: "u-flex-column" }

import router from '@/router';
import { closeToast, showLoadingToast, showToast } from 'vant';
import { getStorage } from '@/utils/local'
import { ref, onMounted, shallowRef, getCurrentInstance, nextTick, proxyRefs, useAttrs, reactive } from 'vue'
import poster from '../poster/index.vue'

/****************组件、js等引入*************/
interface Props {
    missionList: Array,
    rwwwcan: string,
    rwywcan: string,
    missionHeight: number,
    missionPaddingTop: number
    //   rwbkt:string
    //?:表示可选属性；:表示必选属性
}
//props默认值

export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  props: {
    missionList: { default: [] },
    rwwwcan: { default: "" },
    rwywcan: { default: "" },
    missionHeight: { default: 0 },
    missionPaddingTop: { default: 0 }
  },
  emits: ['missionClose'],
  setup(__props: any, { expose: __expose, emit: __emit }) {

const { proxy } = (getCurrentInstance() as any)


/****************渲染dom数据*************/
let imgUrl = ref(process.env.VUE_APP_IMG_URL)
let show = ref(false)
let posterShow = ref(false)
let imageShow = ref(false)
let number = ref('')
let checked = ref('0')
let isChecked = ref(true)
let step = ref(1)
let rwbkt = ref("")
let imageUrl = ref('')
let popupImage = ref('')
let currentLink = ref('')
let currentTaskId = ref(null)  //当前点击任务id
/****************渲染dom数据*************/
// const props = defineProps({})//非ts环境
const props = __props
/****************参数props*************/
const emits = __emit

/***************将组件内给父组件的值暴露出去*****************/
__expose({ show, rwbkt })
/***************将组件内给父组件的值暴露出去*****************/
/****************函数*************/
let finish = (item: any) => {
    currentLink.value = item.content
    currentTaskId.value = item.id
    if (item.popupImage) {
        popupImage.value = item.popupImage
        imageShow.value = true
        return ;
    }
    if (item.type == 1) {
        if (item.completeNum >= item.upperLimitNum) {
            return showToast('任务已达上限')
        };
        proxy.$api.taskBrowse({ activityTaskId: item.id }).then((res: any) => {
            window.location.href = item.content
            console.log("browse", res)
        })
    } else if (item.type == 2) {
        posterShow.value = true
    }
    close()
}
let close = () => {
    show.value = false
    emits("missionClose")
}

const getPosterUrl = (url: string) => {
    imageUrl.value = url
}

//跳转链接
const openUrl = () =>{
    window.location.href = currentLink.value
}
/****************函数*************/

/****************生命周期*************/
// createApp(()=>{
//     console.log("created")
// })
onMounted(async () => {
})
/****************生命周期*************/

return (_ctx: any,_cache: any) => {
  const _component_van_popup = _resolveComponent("van-popup")!
  const _component_van_image = _resolveComponent("van-image")!
  const _component_van_icon = _resolveComponent("van-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_van_popup, {
      show: _unref(show),
      "onUpdate:show": _cache[1] || (_cache[1] = ($event: any) => (_isRef(show) ? (show).value = $event : show = $event)),
      position: "bottom",
      "close-on-click-overlay": false
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", {
          class: "container",
          style: _normalizeStyle({ backgroundImage: `url(${_unref(imgUrl)+_unref(rwbkt)})`, height: _ctx.missionHeight + 'rem', paddingTop: _ctx.missionPaddingTop + 'rem' })
        }, [
          _createElementVNode("img", {
            src: _imports_0,
            class: "close",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_unref(close) && _unref(close)(...args)))
          }),
          _createElementVNode("div", {
            class: "mission-list",
            style: _normalizeStyle({ height: (_ctx.missionHeight) - (_ctx.missionPaddingTop) + 'rem' })
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.missionList, (item, index) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "item u-flex u-ai-c",
                key: index
              }, [
                _createElementVNode("img", {
                  src: item.iconImage,
                  class: "icon",
                  alt: ""
                }, null, 8, _hoisted_2),
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("p", _hoisted_4, _toDisplayString(item.name), 1),
                  _createElementVNode("p", _hoisted_5, _toDisplayString(item.describe), 1)
                ]),
                (item.completeNum < item.upperLimitNum)
                  ? (_openBlock(), _createElementBlock("img", {
                      key: 0,
                      src: _unref(imgUrl)+_ctx.rwwwcan,
                      class: "button",
                      onClick: ($event: any) => (_unref(finish)(item)),
                      alt: ""
                    }, null, 8, _hoisted_6))
                  : (_openBlock(), _createElementBlock("img", {
                      key: 1,
                      onClick: ($event: any) => (_unref(finish)(item)),
                      src: _unref(imgUrl)+_ctx.rwywcan,
                      class: "button",
                      alt: ""
                    }, null, 8, _hoisted_7))
              ]))
            }), 128))
          ], 4)
        ], 4)
      ]),
      _: 1
    }, 8, ["show"]),
    _createVNode(_component_van_popup, {
      show: _unref(posterShow),
      "onUpdate:show": _cache[3] || (_cache[3] = ($event: any) => (_isRef(posterShow) ? (posterShow).value = $event : posterShow = $event)),
      position: "center",
      "close-on-click-overlay": false
    }, {
      default: _withCtx(() => [
        _createVNode(_component_van_image, {
          width: "80vw",
          height: "70vh",
          src: _unref(imageUrl),
          fit: "contain"
        }, null, 8, ["src"]),
        _cache[6] || (_cache[6] = _createElementVNode("div", { class: "txt" }, "长按图片保存", -1)),
        _createVNode(_component_van_icon, {
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_isRef(posterShow) //@ts-ignore
 ? posterShow.value = false : posterShow=false)),
          style: {"margin-top":"30px"},
          name: "close",
          size: "30",
          color: "#fff"
        })
      ]),
      _: 1
    }, 8, ["show"]),
    _createVNode(_component_van_popup, {
      show: _unref(imageShow),
      "onUpdate:show": _cache[5] || (_cache[5] = ($event: any) => (_isRef(imageShow) ? (imageShow).value = $event : imageShow = $event)),
      position: "center",
      "close-on-click-overlay": false
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_8, [
          _createVNode(_component_van_image, {
            onClick: openUrl,
            style: {"max-width":"90vw","max-height":"80vh"},
            src: _unref(imgUrl)+_unref(popupImage),
            fit: "contain"
          }, null, 8, ["src"]),
          _createVNode(_component_van_icon, {
            onClick: _cache[4] || (_cache[4] = ($event: any) => (_isRef(imageShow) //@ts-ignore
 ? imageShow.value = false : imageShow=false)),
            style: {"margin-top":"30px"},
            name: "close",
            size: "30",
            color: "#fff"
          })
        ])
      ]),
      _: 1
    }, 8, ["show"]),
    (_unref(posterShow))
      ? (_openBlock(), _createBlock(poster, {
          key: 0,
          onGetPosterUrl: getPosterUrl,
          activityTaskId: _unref(currentTaskId)
        }, null, 8, ["activityTaskId"]))
      : _createCommentVNode("", true)
  ]))
}
}

})