import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString } from "vue"
import _imports_0 from '@/assets/images/back.png'
import _imports_1 from '@/assets/images/zjgs-title.png'
import _imports_2 from '@/assets/images/zjgs-empty.png'
import _imports_3 from '@/assets/images/wzj.png'
import _imports_4 from '@/assets/images/yzj.png'


const _hoisted_1 = { class: "wrapper" }
const _hoisted_2 = { class: "h" }
const _hoisted_3 = { class: "list" }
const _hoisted_4 = {
  key: 0,
  class: "none"
}
const _hoisted_5 = {
  key: 1,
  class: "item-name u-flex u-jc-c u-ai-c"
}
const _hoisted_6 = { class: "list-body" }
const _hoisted_7 = {
  class: "value",
  style: {"margin-right":"30px"}
}
const _hoisted_8 = { class: "value" }
const _hoisted_9 = { class: "value" }
const _hoisted_10 = {
  key: 2,
  class: "number u-flex u-jc-sb u-ai-c"
}
const _hoisted_11 = { class: "number-value" }
const _hoisted_12 = {
  key: 0,
  src: _imports_3,
  class: "status"
}
const _hoisted_13 = {
  key: 1,
  src: _imports_4,
  class: "status"
}

import router from '@/router';
import { closeToast, showLoadingToast, showToast } from 'vant';
import { getStorage } from '@/utils/local'
import { ref, onMounted, shallowRef, getCurrentInstance, nextTick, proxyRefs, useAttrs, reactive } from 'vue'


/****************组件、js等引入*************/

export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  setup(__props) {

const { proxy } = (getCurrentInstance() as any)


/****************渲染dom数据*************/
let historyList = ref([])
let reserveCode = ref("")
let isWin = ref(0)
/****************渲染dom数据*************/


/****************函数*************/
let back = () => {
    proxy.$router.go(-1)
}
let getHistoryList = () => {
    proxy.$api.getWinnerList().then((res: any) => {
        console.log("winner", res)
        historyList.value = res.data.list || []
        reserveCode.value = res.data.reserveCode || ''
        isWin.value = res.data.isWin || 0
    })
}
/****************函数*************/

/****************生命周期*************/
// createApp(()=>{
//     console.log("created")
// })
onMounted(async () => {
    //缓存 setStorage方法调用示例
    // proxy.$setStorage('TEXT_DATA', { test: 1 })
    // console.log(proxy.$getStorage('TEXT_DATA'));
    // nextTick(() => {
    //     dataHandle()
    //     // if (guideRef.value) {
    //     //     guideRef.value.show = true
    //     // }
    // })
    //调用方法
    //首页
    nextTick(() => {
        getHistoryList()
    })
    // showToast('提示内容');
    //请求接口示例
    // let res = await proxy.$api.getBannerDetails({ partitionType: 1 })
    // console.log(res);

})
/****************生命周期*************/

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      src: _imports_0,
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_unref(back) && _unref(back)(...args))),
      class: "back"
    }),
    _cache[5] || (_cache[5] = _createElementVNode("img", {
      src: _imports_1,
      class: "title"
    }, null, -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[4] || (_cache[4] = _createElementVNode("p", { class: "list-title" }, "数字人民币预约抽奖活动", -1)),
        (_unref(historyList).length == 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, _cache[1] || (_cache[1] = [
              _createElementVNode("img", {
                src: _imports_2,
                class: "none-icon",
                alt: ""
              }, null, -1)
            ])))
          : _createCommentVNode("", true),
        (_unref(historyList).length > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, _cache[2] || (_cache[2] = [
              _createElementVNode("div", { class: "name" }, "序号", -1),
              _createElementVNode("div", { class: "name" }, "用户抽奖号码", -1),
              _createElementVNode("div", { class: "name" }, "奖品", -1)
            ])))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_6, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(historyList), (item, index) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "item u-flex u-jc-sb u-ai-c",
              key: index
            }, [
              _createElementVNode("div", _hoisted_7, _toDisplayString(item.noId), 1),
              _createElementVNode("div", _hoisted_8, _toDisplayString(item.reserveCode), 1),
              _createElementVNode("div", _hoisted_9, _toDisplayString(item.name), 1)
            ]))
          }), 128))
        ]),
        (_unref(historyList).length > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
              _createElementVNode("div", null, [
                _cache[3] || (_cache[3] = _createElementVNode("p", null, "您的抽奖号码为:", -1)),
                _createElementVNode("p", _hoisted_11, _toDisplayString(_unref(reserveCode)), 1)
              ]),
              (_unref(isWin) == 0)
                ? (_openBlock(), _createElementBlock("img", _hoisted_12))
                : _createCommentVNode("", true),
              (_unref(isWin) == 1)
                ? (_openBlock(), _createElementBlock("img", _hoisted_13))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _cache[6] || (_cache[6] = _createElementVNode("div", { class: "list-border" }, null, -1)),
    _cache[7] || (_cache[7] = _createElementVNode("div", { class: "ad" }, " [广告] ", -1))
  ]))
}
}

})