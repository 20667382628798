import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, isRef as _isRef, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/close2.png'


const _hoisted_1 = { class: "popup" }
const _hoisted_2 = ["innerHTML"]

import router from '@/router';
import { closeToast, showLoadingToast, showToast } from 'vant';
import { getStorage } from '@/utils/local'
import { ref, onMounted, shallowRef, getCurrentInstance, nextTick, proxyRefs, useAttrs, reactive } from 'vue'


/****************组件、js等引入*************/
interface Props {
  popTxt:string,
  prizeName:string,
  drawBg:string,
  drawHeight:string,
  drawWidth:string,
  drawPadding:string,
  prizeType:number,
  drawPaddingRight:string
  //?:表示可选属性；:表示必选属性
}
//props默认值

export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  props: {
    popTxt: { default: "" },
    prizeName: {},
    drawBg: { default: "" },
    drawHeight: { default: "" },
    drawWidth: { default: "" },
    drawPadding: { default: "" },
    prizeType: { default: 0 },
    drawPaddingRight: { default: "" }
  },
  emits: ['methods','prizeRead','reserveRead','jumpMiss','close'],
  setup(__props: any, { expose: __expose, emit: __emit }) {

const { proxy } = (getCurrentInstance() as any)


/****************渲染dom数据*************/
let show = ref(false)
/****************渲染dom数据*************/
/***************将组件内给父组件的值暴露出去*****************/
__expose({ show })
/***************将组件内给父组件的值暴露出去*****************/
/****************参数props*************/
// const props = defineProps({})//非ts环境
const props = __props
/****************参数props*************/
/****************函数*************/
const emits = __emit
let goOpen =()=>{
    window.location.href = 'https://www.abchina.com/web2native|channel=wallet_home*menu=wallet_home*step=02*param=1110'
    show.value = false
}
let jumpPrize =()=>{
    console.log("jumpPirze",props.prizeType)
    if(props.prizeType != 0){
        proxy.$router.push('/prize')
    }
    close()
}
let close =()=>{
    console.log("close")
    show.value = false
    emits("close")
}
/****************函数*************/

/****************生命周期*************/
// createApp(()=>{
//     console.log("created")
// })
onMounted(async () => {
})
  /****************生命周期*************/


return (_ctx: any,_cache: any) => {
  const _component_van_popup = _resolveComponent("van-popup")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_van_popup, {
      show: _unref(show),
      "onUpdate:show": _cache[2] || (_cache[2] = ($event: any) => (_isRef(show) ? (show).value = $event : show = $event)),
      "close-on-click-overlay": false
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", {
          class: "container",
          style: _normalizeStyle({backgroundImage:`url(${_ctx.drawBg})`,height:_ctx.drawHeight,width:_ctx.drawWidth,paddingTop:_ctx.drawPadding})
        }, [
          _createElementVNode("img", {
            src: _imports_0,
            class: "close",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_unref(close) && _unref(close)(...args)))
          }),
          _createElementVNode("div", {
            class: "content",
            innerHTML: _ctx.popTxt,
            style: _normalizeStyle({paddingRight:_ctx.drawPaddingRight})
          }, null, 12, _hoisted_2),
          _createElementVNode("div", {
            class: "close-area",
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_unref(jumpPrize) && _unref(jumpPrize)(...args)))
          })
        ], 4)
      ]),
      _: 1
    }, 8, ["show"])
  ]))
}
}

})