import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createVNode as _createVNode } from "vue"
import _imports_0 from '@/assets/images/back.png'
import _imports_1 from '@/assets/images/prize-title.png'
import _imports_2 from '@/assets/images/none.png'
import _imports_3 from '@/assets/images/use-btn.png'


const _hoisted_1 = { class: "wrapper" }
const _hoisted_2 = { class: "h" }
const _hoisted_3 = { class: "list" }
const _hoisted_4 = {
  key: 0,
  class: "none"
}
const _hoisted_5 = { class: "name" }
const _hoisted_6 = { class: "time" }
const _hoisted_7 = ["onClick"]

import router from '@/router';
import { closeToast, showLoadingToast, showToast } from 'vant';
import { getStorage } from '@/utils/local'
import { ref, onMounted, shallowRef, getCurrentInstance, nextTick, proxyRefs, useAttrs, reactive } from 'vue'
import Popup from './popup/index.vue'

/****************组件、js等引入*************/

export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  setup(__props) {

const { proxy } = (getCurrentInstance() as any)


/****************渲染dom数据*************/
let prizeList = ref([])
let describe = ref("")
let cardNo = ref("")
let cardCode = ref("")
let extData = ref("")
/****************渲染dom数据*************/


/****************函数*************/
let back = () => {
    proxy.$router.go(-1)
}
let getPrizeList = () => {
    let now = new Date().getTime()
    let publishTime = proxy.$getSessionStorage("PUBLISH_TIME")
    console.log("now", now)
    console.log("publishTime", publishTime)
    let publishTimeStamp = new Date(publishTime).getTime()
    console.log("publishTimeStamp", publishTimeStamp)
    let activityType = proxy.$getSessionStorage("ACTIVITY_TYPE")
    if (activityType == 1) {
        if (now >= publishTimeStamp) {
            console.log("时间已经过了公示时间")
            proxy.$api.getPrizeList().then((res: any) => {
                console.log("prizeList", res)
                prizeList.value = res.data
            })
        }
    } else {
        console.log("时间已经过了公示时间")
        proxy.$api.getPrizeList().then((res: any) => {
            console.log("prizeList", res)
            prizeList.value = res.data
        })
    }

}
let goUse = (item: any) => {
    console.log("gouse", window)
    console.log("item", item)
    if (item.status != 1 && (item.type != 3 && item.type != 4)) {
        return
    }
    if (item.type == 1) {
        proxy.$api.getPrizeClaim({ prizeId: item.id }).then((res: any) => {
            console.log('领取', res)
            window.AlipayJSBridge.call('abcShare', { method: 'startMiniProgram', username: res.data.appid, path: res.data.path, miniProgramType: 0 })
        })
    } else if (item.type == 2) {
        //只有这里2调
        console.log("extData", item.extData)
        window.location.href = item.extData
    } else if (item.type == 3 || item.type == 4) {
        console.log("打开弹窗")
        describe.value = item.describe
        cardNo.value = item.cardNo
        cardCode.value = item.cardCode
        extData.value = item.extData
        proxy.$refs.popup.show = true
    }
    //跳转微信小程序
    //农行app自带方法
    // window.AlipayJSBridge.call('abcShare', {method: 'startMiniProgram',username: 'gh_047dccaba7ca',path:'/pages/Activity/smallshopZL/smallshopZL?Url=https://uat-w.all-pay.cn&routes=agriculturalbankxmyycj&appId=wxa2a4ee6f7d6856fb', miniProgramType: 0})
}
/****************函数*************/

/****************生命周期*************/
// createApp(()=>{
//     console.log("created")
// })
onMounted(async () => {
    //缓存 setStorage方法调用示例
    // proxy.$setStorage('TEXT_DATA', { test: 1 })
    // console.log(proxy.$getStorage('TEXT_DATA'));
    // nextTick(() => {
    //     dataHandle()
    //     // if (guideRef.value) {
    //     //     guideRef.value.show = true
    //     // }
    // })
    //调用方法
    //首页
    nextTick(() => {
        getPrizeList()
        // proxy.$refs.popup.show = true

    })
    // showToast('提示内容');
    //请求接口示例
    // let res = await proxy.$api.getBannerDetails({ partitionType: 1 })
    // console.log(res);

})
/****************生命周期*************/

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      src: _imports_0,
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_unref(back) && _unref(back)(...args))),
      class: "back"
    }),
    _cache[2] || (_cache[2] = _createElementVNode("img", {
      src: _imports_1,
      class: "title"
    }, null, -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_unref(prizeList).length == 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, _cache[1] || (_cache[1] = [
              _createElementVNode("img", {
                src: _imports_2,
                class: "none-icon",
                alt: ""
              }, null, -1)
            ])))
          : _createCommentVNode("", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(prizeList), (item, index) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "item u-flex u-jc-sb u-ai-c",
            key: index
          }, [
            _createElementVNode("div", null, [
              _createElementVNode("div", _hoisted_5, _toDisplayString(item.name), 1),
              _createElementVNode("div", _hoisted_6, "获得时间：" + _toDisplayString(item.createTime), 1)
            ]),
            _createElementVNode("img", {
              src: _imports_3,
              onClick: ($event: any) => (_unref(goUse)(item)),
              class: "use"
            }, null, 8, _hoisted_7)
          ]))
        }), 128))
      ])
    ]),
    _cache[3] || (_cache[3] = _createElementVNode("div", { class: "list-border" }, null, -1)),
    _createVNode(Popup, {
      ref: "popup",
      describe: _unref(describe),
      cardNo: _unref(cardNo),
      cardCode: _unref(cardCode),
      extData: _unref(extData)
    }, null, 8, ["describe", "cardNo", "cardCode", "extData"]),
    _cache[4] || (_cache[4] = _createElementVNode("div", { class: "ad" }, " [广告] ", -1))
  ]))
}
}

})