import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "wrapper u-flex u-jc-c" }

import { ref, onMounted, shallowRef, getCurrentInstance } from 'vue'
/****************组件、js等引入*************/
/****************组件、js等引入*************/

export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  setup(__props) {

const { proxy } = (getCurrentInstance() as any)

/****************渲染dom数据*************/
let pageName = ref('404')
let err = ref(true)
/****************渲染dom数据*************/

/****************函数*************/

/****************函数*************/

/****************生命周期*************/
onMounted(async () => {

})
/****************生命周期*************/


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("svg", {
      class: "icon u-w-500 u-m-t-300 u-m-l-60",
      t: "1687661252120",
      viewBox: "0 0 2038 1024",
      version: "1.1",
      xmlns: "http://www.w3.org/2000/svg",
      "p-id": "5083",
      "xmlns:xlink": "http://www.w3.org/1999/xlink",
      width: "398.046875",
      height: "200"
    }, [
      _createElementVNode("path", {
        d: "M617.366267 874.091886v132.939791l-83.470114-2.551627 83.470114-132.939791v2.551627z",
        fill: "#F9FAFC",
        "p-id": "5084"
      }),
      _createElementVNode("path", {
        d: "M617.366267 874.091886v132.939791h86.117427l-86.117427-132.939791",
        fill: "#F2F5FA",
        "p-id": "5085"
      }),
      _createElementVNode("path", {
        d: "M444.908145 791.163993v220.07787l-139.829185-4.242081 139.829185-220.07787z",
        fill: "#F9FAFC",
        "p-id": "5086"
      }),
      _createElementVNode("path", {
        d: "M444.908145 791.163993v220.07787h144.071266l-144.071266-220.07787",
        fill: "#F2F5FA",
        "p-id": "5087"
      }),
      _createElementVNode("path", {
        d: "M1224.557919 791.163993v220.07787l-139.829185-4.242081 139.829185-220.07787z",
        fill: "#F9FAFC",
        "p-id": "5088"
      }),
      _createElementVNode("path", {
        d: "M1224.557919 791.163993v220.07787h144.071266l-144.071266-220.07787",
        fill: "#F2F5FA",
        "p-id": "5089"
      }),
      _createElementVNode("path", {
        d: "M1408.211307 724.757888v290.789846l-183.653388-5.581685 183.653388-290.789846z",
        fill: "#F9FAFC",
        "p-id": "5090"
      }),
      _createElementVNode("path", {
        d: "M1408.211307 724.757888v290.789846h189.235072l-189.235072-290.789846",
        fill: "#F2F5FA",
        "p-id": "5091"
      }),
      _createElementVNode("path", {
        d: "M905.445009 654.014017v357.227846l-227.47759-6.857499 227.47759-357.227846z",
        fill: "#F9FAFC",
        "p-id": "5092"
      }),
      _createElementVNode("path", {
        d: "M905.445009 654.014017v357.227846h234.366983l-234.366983-357.227846",
        fill: "#F2F5FA",
        "p-id": "5093"
      }),
      _createElementVNode("path", {
        d: "M713.977262 274.45943h-228.147391a23.283601 23.283601 0 0 1-13.364149 42.357016H116.513689a23.283601 23.283601 0 0 1 0-46.567201H405.070861a14.352905 14.352905 0 0 0 14.352905-14.352905v-9.281545a14.352905 14.352905 0 0 0-14.352905-14.352904H23.315496a23.283601 23.283601 0 1 1 0-46.567202h321.345585a23.283601 23.283601 0 0 1 13.396044-42.32512h199.154524a23.283601 23.283601 0 0 1 0 46.567201h-71.381778l0.350849 0.287058a14.352905 14.352905 0 0 0-11.609905 14.065847V213.698801a14.352905 14.352905 0 0 0 11.609905 14.065846l-0.350849 0.287058h228.147391a23.283601 23.283601 0 1 1 0.47843 46.407725z",
        fill: "#E6EAED",
        "p-id": "5094"
      }),
      _createElementVNode("path", {
        d: "M2014.796948 469.371874H1633.041582a14.352905 14.352905 0 0 0-14.352904 14.352904v9.409126a14.352905 14.352905 0 0 0 14.352904 14.352905h288.620963a23.283601 23.283601 0 0 1 0 46.567201H1565.646722a23.283601 23.283601 0 0 1-13.364149-42.32512h-228.147392a23.283601 23.283601 0 1 1 0-46.567202h228.243078l-0.350849-0.287058a14.352905 14.352905 0 0 0 11.609905-14.065846v-9.409127a14.352905 14.352905 0 0 0-11.609905-14.065846l0.350849-0.287058H1480.900794a23.283601 23.283601 0 0 1 0-46.567202h199.154524a23.283601 23.283601 0 0 1 13.396045 42.357017h321.345585a23.283601 23.283601 0 1 1 0.574116 46.567201z",
        fill: "#E6EAED",
        "p-id": "5095"
      }),
      _createElementVNode("path", {
        d: "M1538.12104 304.249681h-33.90475a21.178508 21.178508 0 0 1-0.510325-42.357016h34.415075a21.178508 21.178508 0 0 1 0.510326 42.357016z m-1131.32783 207.319732H372.856564a21.178508 21.178508 0 0 1 0-42.325121h34.000436a21.178508 21.178508 0 0 1 0 42.325121z",
        fill: "#E6EAED",
        "p-id": "5096"
      }),
      _createElementVNode("path", {
        d: "M910.994798 596.379131h59.325339v423.378788h-59.325339z",
        fill: "#999999",
        "p-id": "5097"
      }),
      _createElementVNode("path", {
        d: "M970.320137 1022.947454h-59.325339a3.189534 3.189534 0 0 1-3.189534-3.189535V596.379131a3.189534 3.189534 0 0 1 3.189534-3.189534h59.325339a3.189534 3.189534 0 0 1 3.189534 3.189534v423.378788a3.189534 3.189534 0 0 1-3.189534 3.189535z m-56.135804-6.379069h52.94627V599.568665h-52.94627z",
        fill: "#54333C",
        "p-id": "5098"
      }),
      _createElementVNode("path", {
        d: "M1237.539324 178.390656L1063.51833 6.538545l-244.732969 1.307709-172.234855 173.829622 1.307709 244.541598 173.989099 171.979692 244.732969-1.30771 172.234855-173.829621z m-183.780969 387.018097l-218.897741 1.180127L679.370815 412.757639l-1.180128-218.738265 153.799346-155.585485 218.897741-1.180128 155.617381 153.831241 1.180128 218.738265z",
        fill: "#CC4444",
        "p-id": "5099"
      }),
      _createElementVNode("path", {
        d: "M819.359477 604.480548l-177.752749-175.711447-1.339604-249.836225 1.849929-1.881825L816.138047 1.339604l249.995702-1.180127 177.784644 175.424388 1.339604 249.836225-1.84993 1.881826-174.052889 175.679551z m-165.026507-181.069864l170.225448 168.279831 239.438343-1.275813 168.375517-170.065971-1.275813-239.215076-170.161657-168.216041-239.438343 1.275814-168.407413 170.065971z m177.912225 149.557265l-1.881825-1.84993L672.991746 415.436848l-1.339605-223.905311 1.84993-1.881825L829.278929 32.05482l224.192368-1.212023 159.476717 157.562996 1.212023 224.032893-1.84993 1.881825-155.808752 157.371624z m-146.718579-162.889519l151.85373 150.099486 213.698801-1.148232 150.227067-151.726149-1.148232-213.443638-151.85373-150.131381-213.698801 1.148232-150.163277 151.758044z",
        fill: "#54333C",
        "p-id": "5100"
      }),
      _createElementVNode("path", {
        d: "M1206.377574 191.435851l-155.617381-153.831241-218.897742 1.180128-153.958822 155.489799 1.180127 218.738265 155.617381 153.831241 218.897742-1.180128 153.958822-155.489799-1.180127-218.738265z",
        fill: "#CC4444",
        "p-id": "5101"
      }),
      _createElementVNode("path", {
        d: "M832.085719 573.255007l-159.476717-157.531101-1.212023-224.000997L829.278929 32.437564l224.192368-1.212023 159.476717 157.562997 1.212023 224.000996-2.136988 2.009407-155.744961 157.244043z m-146.71858-162.889519l151.85373 150.099486 213.698801-1.148232 150.227067-151.726149-1.148232-213.443638-151.85373-150.131381-213.698801 1.148232-150.227067 151.726149z",
        fill: "#54333C",
        "p-id": "5102"
      }),
      _createElementVNode("path", {
        d: "M944.899548 177.210528a16.936427 16.936427 0 1 1 16.968323-16.936427 16.936427 16.936427 0 0 1-16.968323 16.936427z m0 249.804329a16.936427 16.936427 0 1 1-16.936427 16.936428 16.936427 16.936427 0 0 1 16.936427-16.936428z m-57.60299-106.945086h-16.936427V235.291948h-19.424264l-58.623641 87.201869v10.844417h61.78128v34.38318h16.266625v-34.38318h16.936427v-13.268463z m-77.697056 0l39.454539-58.910699a85.543311 85.543311 0 0 0 5.039465-10.174615h0.350848c-0.255163 5.13515-0.350849 9.568603-0.350848 13.587417v55.497897z m102.798691 33.01168a35.308145 35.308145 0 0 0 31.257437 16.840742 37.732191 37.732191 0 0 0 33.234948-17.733811q11.865068-17.765706 11.865068-51.255817 0-67.937081-42.101854-67.937081a37.732191 37.732191 0 0 0-33.713378 17.988973q-11.609905 17.988974-11.609905 53.041956 0 32.150506 11.067684 48.991248z m33.330634-106.083912q25.835228 0 25.835228 55.497898 0 53.584177-26.345553 53.584177T918.58589 303.165239q0-56.327176 27.302414-56.327176z m147.069429 73.104127h-16.968323V235.291948h-19.392369l-58.623641 87.201869v10.844417h61.78128v34.38318h16.23473v-34.38318h16.968323z m-77.697057 0l39.45454-58.910699a85.543311 85.543311 0 0 0 5.039464-10.174614h0.350849c-0.255163 5.13515-0.350849 9.568603-0.350849 13.587416v55.497897z",
        fill: "#FFFEFA",
        "p-id": "5103"
      }),
      _createElementVNode("path", {
        d: "M279.658371 1015.515839H554.978975v8.484161H279.658371z",
        fill: "#54333C",
        "p-id": "5104"
      }),
      _createElementVNode("path", {
        d: "M572.042984 1015.515839h42.357016v8.484161h-42.357016z",
        fill: "#54333C",
        "p-id": "5105"
      }),
      _createElementVNode("path", {
        d: "M644.062669 1015.515839h97.440274v8.484161h-97.440274z",
        fill: "#54333C",
        "p-id": "5106"
      }),
      _createElementVNode("path", {
        d: "M1114.391403 1015.515839h186.428282v8.484161h-186.428282z",
        fill: "#54333C",
        "p-id": "5107"
      }),
      _createElementVNode("path", {
        d: "M1334.724435 1015.515839h59.325339v8.484161h-59.325339z",
        fill: "#54333C",
        "p-id": "5108"
      }),
      _createElementVNode("path", {
        d: "M1423.712444 1015.515839h241.51154v8.484161h-241.51154z",
        fill: "#54333C",
        "p-id": "5109"
      }),
      _createElementVNode("path", {
        d: "M783.891855 1015.515839h300.836879v8.484161h-300.836879z",
        fill: "#54333C",
        "p-id": "5110"
      })
    ], -1)
  ])))
}
}

})