import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "version" }

import { ref,onBeforeMount ,getCurrentInstance} from 'vue'

export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const { proxy } = (getCurrentInstance() as any)
let version = ref('v1.0.1')
version.value = process.env.VUE_APP_VERSION
onBeforeMount(async () => {
  console.log("全清") 
  proxy.$removeStorage("jwt")
  proxy.$removeStorage("mobile")
  proxy.$removeStorage("AID")
})

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_router_view),
    _createElementVNode("div", _hoisted_1, "v" + _toDisplayString(_unref(version)), 1)
  ], 64))
}
}

})