import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, renderList as _renderList, Fragment as _Fragment, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "btnIsland reserve-btnIsland"
}
const _hoisted_2 = ["src"]
const _hoisted_3 = ["src"]
const _hoisted_4 = ["src"]
const _hoisted_5 = ["src"]
const _hoisted_6 = ["src"]
const _hoisted_7 = {
  key: 1,
  class: "btnIsland u-flex u-fd-c u-ai-c"
}
const _hoisted_8 = ["src"]
const _hoisted_9 = ["src"]
const _hoisted_10 = ["src"]
const _hoisted_11 = ["src"]
const _hoisted_12 = ["src"]
const _hoisted_13 = ["src"]
const _hoisted_14 = ["src"]
const _hoisted_15 = ["src"]
const _hoisted_16 = ["src"]
const _hoisted_17 = ["src", "onClick"]
const _hoisted_18 = ["src"]
const _hoisted_19 = ["src"]
const _hoisted_20 = ["src"]
const _hoisted_21 = { class: "bg" }
const _hoisted_22 = ["src"]
const _hoisted_23 = ["src"]
const _hoisted_24 = ["innerHTML"]
const _hoisted_25 = ["src"]

import router from '@/router';
import { closeToast, showLoadingToast, showToast } from 'vant';
import { getStorage } from '@/utils/local'
import { ref, onMounted, shallowRef, getCurrentInstance, nextTick, onActivated, onUpdated, onDeactivated, computed } from 'vue'
import Popup from './components/popup/index.vue'
import VerifyPop from './components/verify-pop/index.vue'
import RulePop from './components/rule-pop/index.vue'
import finish from './components/finish/index.vue'
import DrawPop from './components/drawPop/index.vue'
import MissionPop from './components/missionPop/index.vue'
import DrawRule from './components/draw-rule/index.vue'
/****************组件、js等引入*************/

export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  setup(__props) {

const { proxy } = (getCurrentInstance() as any)
/****************渲染dom数据*************/
let isShowAuth = ref(false)
let imgUrl = ref(process.env.VUE_APP_IMG_URL)

let type = ref(0)
let wrapperBgColor = ref("")
let activityType = ref(0)
let popPic = ref("")
let popTxt = ref("")
let status = ref(1)
let bgTop = ref('')
let bgBottom = ref('')
let bottomHeight = ref(0)
let prizeName = ref("")
let urlStr = ref("https://www.abchina.com/luascript/oauthLogin/{\"client_id\":\"f03e1f1b-5e0b-4e24-b45e-44eecd789e58\",\"redirect_uri\":\"https://abcdc.fansiji.com/#/home\",\"state\":\"123456\",\"scope\":\"openid,custinfo\",\"response_type\":\"code\"}")
let jwt = ref("")
let appointmentImg = ref('')
let endTime = ref("")
let imgData = ref<{ [key: string]: string }>({})
let isReserve = ref(false)
let isEnd = ref(false)
let reserveCode = ref("")
let isReadReserve = ref(false)
let reserveType = ref(null)
let missJumpUrl = ref("")
let publishTime = ref("")
let fee = ref("")
let isShowPublicty = ref(false)
let isShowHistory = ref(false)
let testAuth = ref(true)
let aid = ref("")
let cardsList = ref<string[]>([])
let hdgzt = ref("")
let hdgznrt = ref("")
let fpkt = ref("")
let rwbkt = ref("")
let rwwwcan = ref("")
let rwywcan = ref("")
let nonumCopywriting = ref("")
let notWinningCopywriting = ref("")
let surplusnumCopywriting = ref("")
let winningCopywriting = ref("")
let zjtct = ref("")
let wzjtct = ref("")
let csbztct = ref("")
let wdjpant = ref("")
let surplusCount = ref(0)
let missionList = ref([])
let drawBg = ref("")
let drawCopywriting = ref("")
let tzxggift = ref("")
let surplusTop = ref(0)
let missionHeight = ref(0)
let missionPaddingTop = ref(0)
let jdt = ref("")
let czt = ref("")
let dst = ref("")
let isShowHammer = ref(false)
let hammerTop = ref(0)
let hammerLeft = ref(0)
let isShowCrack = ref(false)
let crackTop = ref(0)
let crackLeft = ref(0)
let drawHeight = ref("")
let drawWidth = ref("")
let drawPadding = ref("")
let mht = ref("")
let missionWidth = ref("")
let wt = ref("")
let isRolling = ref(false)
let prizeType = ref(0)
let drawPaddingRight = ref("")
let loading = ref(true)
/****************渲染dom数据*************/
//https://www.abchina.com/web2native|channel=wallet_home*menu=wallet_home*step=02*param=1110

/****************计算属性*************/
let surplusText = computed(() => {
    let value = ''
    if (surplusnumCopywriting.value) {
        value = surplusnumCopywriting.value.replace('{surplusNum}', surplusCount.value) || ''
    }
    return value
})
/****************计算属性*************/

/****************函数*************/
let methods = () => {
    proxy.$api.getRead().then((res: any) => {

    })
}
let drawClose = () => {
    isShowCrack.value = false
}
let openMission = () => {
    let endTimeStamp = new Date(endTime.value).getTime()
    let now = new Date().getTime()
    if (endTimeStamp < now) {
        return showToast("活动已结束")
    }
    getMissionList()
    proxy.$refs.missionRef.show = true
}
let rolling = () => {
    isRolling.value = true
    setTimeout(() => {
        isRolling.value = false
        draw()
    }, 500)
}
let smash = (i: any) => {
    isShowHammer.value = true
    let topMap = new Map()
    let leftMap = new Map()
    topMap.set(1, '-0.3rem')
    topMap.set(2, '-0.3rem')
    topMap.set(3, '-0.3rem')
    topMap.set(4, '3rem')
    topMap.set(5, '3rem')
    topMap.set(6, '3rem')
    hammerTop.value = topMap.get(i)
    leftMap.set(1, '0.7rem')
    leftMap.set(2, '2.7rem')
    leftMap.set(3, '4.7rem')
    leftMap.set(4, '0.7rem')
    leftMap.set(5, '2.7rem')
    leftMap.set(6, '4.7rem')
    hammerLeft.value = leftMap.get(i)

    let crackTopMap = new Map()
    let crackLeftMap = new Map()
    crackTopMap.set(1, '0')
    crackTopMap.set(2, '0')
    crackTopMap.set(3, '0')
    crackTopMap.set(4, '3rem')
    crackTopMap.set(5, '3rem')
    crackTopMap.set(6, '3rem')
    crackTop.value = crackTopMap.get(i)
    crackLeftMap.set(1, '0')
    crackLeftMap.set(2, '2.1rem')
    crackLeftMap.set(3, '4.1rem')
    crackLeftMap.set(4, '0')
    crackLeftMap.set(5, '2.1rem')
    crackLeftMap.set(6, '4.1rem')
    crackLeft.value = crackLeftMap.get(i)
    setTimeout(() => {
        isShowHammer.value = false
        isShowCrack.value = true
        setTimeout(() => {
            draw()
        }, 200)
    }, 600)
}
let draw = () => {
    let mobile = proxy.$getStorage("mobile")
    if (!mobile) {
        return proxy.$refs.verifyRef.show = true
    }
    let heightMap = new Map()
    let widthMap = new Map()
    let paddingMap = new Map()
    let paddingRightMap = new Map()

    heightMap.set(2, '6.2rem')
    heightMap.set(4, '11.2rem')
    heightMap.set(3, '9.5rem')
    heightMap.set(5, '6.8rem')
    drawHeight.value = heightMap.get(activityType.value)
    widthMap.set(2, '7.9rem')
    widthMap.set(4, '9.9rem')
    widthMap.set(3, '7.9rem')
    widthMap.set(5, '7.9rem')
    drawWidth.value = widthMap.get(activityType.value)
    paddingMap.set(2, '1.4rem')
    paddingMap.set(4, '3.4rem')
    paddingMap.set(3, '3.3rem')
    paddingMap.set(5, '1.8rem')
    drawPadding.value = paddingMap.get(activityType.value)
    paddingRightMap.set(3, '1.9rem')
    paddingRightMap.set(4, '2.6rem')
    drawPaddingRight.value = paddingRightMap.get(activityType.value)
    //博饼中奖类型
    let bobingMap = new Map()
    bobingMap.set(1, '一秀')
    bobingMap.set(2, '二举')
    bobingMap.set(3, '四进')
    bobingMap.set(4, '三红')
    bobingMap.set(5, '对堂')
    bobingMap.set(6, '四红')
    bobingMap.set(7, '五子登科')
    bobingMap.set(8, '五王')
    bobingMap.set(9, '黑六勃')
    bobingMap.set(10, '遍地锦')
    bobingMap.set(11, '红六勃')
    bobingMap.set(12, '状元插金花')
    //奖品类型
    let prizeTypeMap = new Map()
    prizeTypeMap.set(0, '谢谢参与')
    prizeTypeMap.set(1, '微信立减金')
    prizeTypeMap.set(2, '农行商圈券')
    prizeTypeMap.set(3, '端内核销第三方卡券')
    prizeTypeMap.set(4, '端外核销第三方卡券')
    proxy.$api.drawLottery().then((res: any) => {
        console.log("res", res)
        // if(res.data.prizeType != 0){
        //     drawBg.value = zjtct.value
        // }

        drawBg.value = res.data.prizeType == 0 ? wzjtct.value : zjtct.value
        prizeType.value = res.data.prizeType
        if (res.data.prizeType == 0) {
            if (activityType.value == 3) {
                drawPadding.value = '3.2rem'
            }
            drawCopywriting.value = notWinningCopywriting.value.replace("{prizeName}", res.data.prizeName).replace("{prizeType}", prizeTypeMap.get(res.data.prizeType))
        }
        if (res.data.prizeType != 0) {
            drawCopywriting.value = winningCopywriting.value.replace("{prizeName}", res.data.prizeName).replace("{prizeType}", prizeTypeMap.get(res.data.prizeType)).replace("{bobingType}", bobingMap.get(res.data.bobingType))
            // drawCopywriting.value = winningCopywriting.value.replace("{prizeType}",'')
        }
        proxy.$refs.drawRef.show = true
        getUserInfo()
    }).catch((err: any) => {
        isShowCrack.value = false
        console.log("err", err)
        if (err.data.code == '600') {
            drawBg.value = csbztct.value
            drawCopywriting.value = nonumCopywriting.value
            proxy.$refs.drawRef.show = true
        } else if (err.data.code == '601') {
            showToast("奖品库存不足")
        }
    })
}
let prizeRead = () => {
    proxy.$api.prizeRead().then((res: any) => {
        console.log("开奖已读", res)
    })
}
let reserveRead = () => {
    proxy.$api.reserveRead().then((res: any) => {
        console.log("预约已读", res)
    })
}
let jumpMiss = () => {
    proxy.$api.prizeRead().then((res: any) => {
        console.log("开奖已读", res)
        if (missJumpUrl.value) {
            window.location.href = missJumpUrl.value
        }
    })
}
let showRule = () => {
    console.log("?", proxy.$refs.ruleRef.show)
    proxy.$refs.ruleRef.show = true
    // type.value = 3
    // proxy.$refs.popRef.show = true
}
let showDrawRule = () => {
    proxy.$refs.drawRuleRef.show = true
}
let jump = (url: string) => {
    proxy.$router.push(url)
}
let verify = (number: any) => {
    console.log("确认", number)
    proxy.$api.setMobile({ mobile: number }).then((res: any) => {
        console.log("verify", res)
        proxy.$setStorage("mobile", number)
        proxy.$refs.verifyRef.show = false
        showToast("设置成功")
    })
}
let appointment = () => {
    //是否能够预约
    if (!isReserve.value || isEnd.value) {
        return
    }
    let mobile = proxy.$getStorage("mobile")
    if (!mobile) {
        return proxy.$refs.verifyRef.show = true
    }
    //查询数币账户状态
    proxy.$api.getAccountState().then((res: any) => {
        if (!res.data) {
            type.value = 1
            proxy.$refs.popRef.show = true
        } else {
            //调预约接口
            proxy.$api.activityReserve().then((res: any) => {
                console.log("reserve", res)
                getReserveState()
                //跳转支付页
                if (reserveType.value == 2) {
                    window.location.href = res.data
                }
            }).catch((err: any) => {
                console.log("err", err)
                proxy.$router.replace("/index")
            })
        }
    })
}
let auth = () => {
    proxy.$setStorage("IS_AUTH", '1')
    //正式 改回home
    //正式 router里的默认路由改回home
    console.log("跳转授权", `https://www.abchina.com/luascript/oauthLogin/{\"client_id\":\"f03e1f1b-5e0b-4e24-b45e-44eecd789e58\",\"redirect_uri\":\"https://abcdc.fansiji.com/#/home?aid=${aid.value}\",\"state\":\"123456\",\"scope\":\"openid,custinfo\",\"response_type\":\"code\",\"aid\":\"${aid.value}\"}`)

    if (process.env.NODE_ENV == 'development' || process.env.NODE_ENV == 'test') {
        window.location.href = `https://www.abchina.com/luascript/oauthLogin/{\"client_id\":\"f03e1f1b-5e0b-4e24-b45e-44eecd789e58\",\"redirect_uri\":\"https://abcdc.fansiji.com/#/index?aid=${aid.value}\",\"state\":\"123456\",\"scope\":\"openid,custinfo\",\"response_type\":\"code\",\"aid\":\"${aid.value}\"}`
    } else {
        window.location.href = `https://www.abchina.com/luascript/oauthLogin/{\"client_id\":\"f03e1f1b-5e0b-4e24-b45e-44eecd789e58\",\"redirect_uri\":\"https://abcdc.fansiji.com/#/home?aid=${aid.value}\",\"state\":\"123456\",\"scope\":\"openid,custinfo\",\"response_type\":\"code\",\"aid\":\"${aid.value}\"}`
    }
}
//查询用户状态
let getUserState = () => {
    proxy.$api.getUserState().then((res: any) => {
        console.log("userState", res)
        if (res == undefined) {
            console.log("undefined")
            proxy.$removeStorage("jwt")
            proxy.$removeStorage("mobile")
            proxy.$removeStorage("IS_AUTH")
            jwt.value = ""
            isShowAuth.value = true
            return
        }
        if (!res.data.mobile) {
            proxy.$refs.verifyRef.show = true
        }
    })
}
//查询账户信息
let getUserInfo = () => {
    proxy.$api.getUserInfo().then((res: any) => {
        console.log("userInfo", res)
        if (res == undefined) {
            console.log("undefined")
            proxy.$removeStorage("jwt")
            proxy.$removeStorage("mobile")
            proxy.$removeStorage("IS_AUTH")
            jwt.value = ""
            isShowAuth.value = true
            return
        }
        reserveCode.value = res.data.reserveCode
        surplusCount.value = res.data.num
        isReadReserve.value = res.data.isRead || false
    })
}
//查询任务列表
let getMissionList = () => {
    proxy.$api.getMissionList().then((res: any) => {
        console.log("mission", res)
        missionList.value = res.data
    })
}
//查询活动信息
let getActivityInfo = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    let code = ref()
    let state = ref()
    if (process.env.NODE_ENV == 'development' || process.env.NODE_ENV == 'test') {
        aid.value = '1'
        code.value = 'test'
        state.value = '123'
        let testAID = proxy.$getStorage("AID") || urlParams.get('aid') || proxy.$route.query.aid;
        aid.value = testAID
    } else {
        aid.value = urlParams.get('aid') || proxy.$route.query.aid;
        code.value = urlParams.get('code')
        state.value = urlParams.get("state")
        if (!aid.value) {
            return showToast({
                message: "活动已结束",
                duration: 0,
                forbidClick: true
            })
        }
    }
    let res = await proxy.$api.getActivityInfo({ activityId: aid.value })
    if (res.code != 0) {
        if (res.code == 1002) {
            proxy.$refs.finish.show = true
        }
        return
    }
    //获取活动配置信息
    activityType.value = res.data.type
    proxy.$setSessionStorage("ACTIVITY_TYPE", activityType.value)
    imgData.value = res.data.imgData
    //配置背景色
    let bgColorMap = new Map()
    bgColorMap.set(1, '#FDDAB8')
    bgColorMap.set(2, '#FAF0E6')
    bgColorMap.set(3, '#AF0A08')
    bgColorMap.set(4, '#5BD7C1')
    bgColorMap.set(5, '#FBF5F0')
    console.log("bgcolorget", bgColorMap.get(activityType.value))
    wrapperBgColor.value = bgColorMap.get(activityType.value)
    //配置剩余次数文案高度
    let surplusTopMap = new Map()
    surplusTopMap.set(2, '14.6rem')
    surplusTopMap.set(3, '13.4rem')
    surplusTopMap.set(4, '15rem')
    surplusTopMap.set(5, '15rem')
    surplusTop.value = surplusTopMap.get(activityType.value)
    //配置任务弹窗高度
    let missionHeightMap = new Map()
    missionHeightMap.set(2, 8)
    missionHeightMap.set(5, 8)
    missionHeightMap.set(4, 8)
    missionHeightMap.set(3, 15)
    missionHeight.value = missionHeightMap.get(activityType.value)
    //
    let missionPaddingTopMap = new Map()
    missionPaddingTopMap.set(2, 1.5)
    missionPaddingTopMap.set(5, 1.5)
    missionPaddingTopMap.set(4, 1.5)
    missionPaddingTopMap.set(3, 7.2)
    missionPaddingTop.value = missionPaddingTopMap.get(activityType.value)
    //头图
    if (res.data.type == 1) {
        bgTop.value = res.data.imgData.bgTopImg
    } else {
        bgTop.value = res.data.imgData.bjtt
    }
    //底图
    if (res.data.type == 1) {
        bgBottom.value = res.data.imgData.bgBottomImg
    } else {
        bgBottom.value = res.data.imgData.bjdt
    }
    if (res.data.type != 1) {
        hdgzt.value = res.data.imgData.hdgzt
        hdgzt.value = hdgzt.value.replace('\\', '\\\\')
        hdgznrt.value = res.data.imgData.hdgznrt
        hdgznrt.value = hdgznrt.value.replace('\\', '\\\\')
        fpkt.value = res.data.imgData.fpkt

        rwbkt.value = res.data.imgData.rwbkt

        if (rwbkt.value) {
            rwbkt.value = rwbkt.value.replace('\\', '\\\\')
        }
        proxy.$refs.missionRef.rwbkt = rwbkt.value
        console.log("rwbkt", rwbkt.value)
        rwwwcan.value = res.data.imgData.rwwwcan
        if (rwwwcan.value) {
            rwwwcan.value = rwwwcan.value.replace("\\", "\\\\")
        }
        rwywcan.value = res.data.imgData.rwywcan
        if (rwywcan.value) {
            rwywcan.value = rwywcan.value.replace("\\", "\\\\")
        }
        if (activityType.value == 5) {
            missionWidth.value = '5rem'
        }

        zjtct.value = res.data.imgData.zjtct
        zjtct.value = zjtct.value.replace("\\", "\\\\")
        console.log("zjtct", zjtct.value)
        wzjtct.value = res.data.imgData.wzjtct
        wzjtct.value = wzjtct.value.replace("\\", "\\\\")
        csbztct.value = res.data.imgData.csbztct
        csbztct.value = csbztct.value.replace("\\", "\\\\")
        console.log("zjtct", zjtct.value)
        wdjpant.value = res.data.imgData.wdjpant
        //骰子效果
        wt.value = res.data.imgData.wt
        tzxggift.value = res.data.imgData.tzxggift
        //金蛋图
        jdt.value = res.data.imgData.jdt
        dst.value = res.data.imgData.dst
        czt.value = res.data.imgData.czt
        //盲盒图
        mht.value = res.data.imgData.mht
        //翻牌活动卡片

        let { k1bjt, k2bjt, k3bjt, k4bjt, k5bjt, k6bjt } = res.data.imgData

        cardsList.value = [k1bjt, k2bjt, k3bjt, k4bjt, k5bjt, k6bjt]
        console.log("cardlist", cardsList.value)
    }

    //无次数文案
    nonumCopywriting.value = res.data.nonumCopywriting
    notWinningCopywriting.value = res.data.notWinningCopywriting
    surplusnumCopywriting.value = res.data.surplusnumCopywriting
    winningCopywriting.value = res.data.winningCopywriting
    //预约模块
    endTime.value = res.data.endTime
    publishTime.value = res.data.publishTime
    proxy.$setSessionStorage('PUBLISH_TIME', publishTime.value)
    reserveType.value = res.data.reserveType
    fee.value = res.data.reserveFee
    missJumpUrl.value = res.data.missJumpUrl
    document.title = res.data.name
    let extFunctionComma = res.data.extFunctionComma
    if (extFunctionComma) {
        isShowHistory.value = (extFunctionComma.indexOf('1') != -1)
        isShowPublicty.value = (extFunctionComma.indexOf('2') != -1)
    }
    console.log("show history", isShowHistory.value)
    console.log('赋值')
    let image = new Image()
    image.src = bgTop.value
    image.onload = () => {
        console.log("ready", document.getElementById("bgTop")?.height)
        bottomHeight.value = document.getElementById("bgTop")?.height
    }
    let gif = new Image()
    gif.src = tzxggift.value
    gif.onload = () => {
        console.log("gif")
        // bgBottom.value = tzxggift.value
    }
    //判断是否登录
    const localJwt = proxy.$getStorage("jwt")
    console.log("localJwt", localJwt)
    if (localJwt) {
        jwt.value = JSON.stringify(localJwt)
        //判断预约状态
        if (activityType.value == 1) {
            getReserveState()
        }
        //查询用户状态
        getUserState()
        getUserInfo()
        let now = new Date().getTime()
        let publishEndTime = new Date(publishTime.value).getTime()
        if (publishEndTime < now) {

        }
    }
    console.log("route", proxy.$route)
    console.log("code", code)

    if (code.value && !localJwt) {
        const loginRes = await proxy.$api.getLogin({
            activityId: aid.value,
            code: code.value,
            state: state.value,
            redirectUri: 'https://abcdc.fansiji.com/#/home'
        })
        
        var data = loginRes.data;
        jwt.value = 'Bearer' + ' ' + data.jwt
        proxy.$setStorage("jwt", jwt.value)
        proxy.$setStorage("tokenHeader", 'Authorization')
        proxy.$setStorage("mobile", data.mobile)
        proxy.$setStorage("token", data.token)
        console.log('手机号', data.mobile)
        if (activityType.value == 1) {
            getReserveState()
        }
        getUserState()
        getUserInfo()
        postTaskShare()
    }

    let now = new Date().getTime()
    let publishTimeStamp = new Date(publishTime.value).getTime()
    if (now >= publishTimeStamp) {
        console.log("时间已经过了公示时间")
        proxy.$api.getDrawInform({}).then((res: any) => {
            console.log("inform", res)
            if (res.data.status == 1) {
                type.value = res.data.lotteryStatus == 0 ? 2 : 3
                prizeName.value = res.data.name
                proxy.$refs.popRef.show = true
            }
        }).catch((err: any) => {
            console.log("err", err)
        })
    }
    
}
//任务活动分享
let postTaskShare = () => {
    if (!proxy.$getStorage("activityTaskId") || !proxy.$getStorage("token")) {
        return false
    }

    proxy.$api.postTaskShare({
        activityTaskId: String(proxy.$getStorage("activityTaskId")),
        shareToken: proxy.$getStorage("token")
    }).then((res: any) => {
        proxy.$removeStorage("activityTaskId")
        proxy.$removeStorage("token")
    })
}
let getReserveState = () => {
    proxy.$api.getReserveState().then((res: any) => {
        console.log("reserve", res)
        if (res == undefined) {
            console.log("undefined")
            proxy.$removeStorage("jwt")
            proxy.$removeStorage("mobile")
            proxy.$removeStorage("IS_AUTH")
            jwt.value = ""
            isShowAuth.value = true
            return
        }
        let endTimeStamp = new Date(endTime.value).getTime()
        let publishEndTime = new Date(publishTime.value).getTime()
        let now = new Date().getTime()
        if (!res.data) {
            appointmentImg.value = imgData.value.btnState1Img
            isReserve.value = true
            if (!isReadReserve && endTimeStamp > now) {
                type.value = 5
                proxy.$refs.popRef.show = true
            }
        }
        if (res.data) {
            appointmentImg.value = imgData.value.btnState2Img
        }
        console.log("timeStamp", endTimeStamp)
        if (endTimeStamp < now) {
            appointmentImg.value = imgData.value.btnState3Img
            isEnd.value = true
            // type.value = 2
            //     proxy.$refs.popRef.show = true
            // return
        }

    }).catch((err: any) => {
        console.log("reserveStage,err", err)
        if (err.code == '401') {
            proxy.$removeStorage("jwt")
            proxy.$removeStorage("mobile")
            isShowAuth.value = true
            jwt.value = ""
        }
    })
}
let openReserveCode = () => {
    type.value = reserveCode.value ? 5 : 4
    console.log("open", type.value, isReserve.value)
    proxy.$refs.popRef.show = true
}
let getSearch = (search: string) => {
    console.log("search", search)
    let params = search.split("&")
    console.log("params", params)
    let values = []
    params.forEach((item, index) => {
        values.push(item.split('=')[1])
    })
    console.log("values", values)
    return values
}
/****************函数*************/

/****************生命周期*************/

onMounted(async () => {

    //缓存 setStorage方法调用示例
    //首页
    nextTick(() => {
        // document.getElementById("bgBottom")
        console.log("window.location", window.location)
        getActivityInfo()
        let IS_AUTH = proxy.$getStorage("IS_AUTH")
        console.log("isShowAuth", IS_AUTH)
        if (!IS_AUTH) {
            console.log("isShowAuth2", IS_AUTH)
            isShowAuth.value = true
            console.log("isShowAuth", isShowAuth.value)
        }
        // openMission()
        // proxy.$refs.missionRef.show = true
        // getReserveState()
    })
    // showToast('提示内容');
    //请求接口示例
    // let res = await proxy.$api.getBannerDetails({ partitionType: 1 })
    // console.log(res);

})
/****************生命周期*************/

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      class: "wrapper",
      style: _normalizeStyle({ backgroundColor: _unref(wrapperBgColor) })
    }, [
      (_unref(activityType) == 1)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            (_unref(imgData).btnRuleImg)
              ? (_openBlock(), _createElementBlock("img", {
                  key: 0,
                  src: _unref(imgUrl) + _unref(imgData).btnRuleImg,
                  onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_unref(showRule) && _unref(showRule)(...args))),
                  class: "rule",
                  style: {"width":"90px"},
                  alt: ""
                }, null, 8, _hoisted_2))
              : _createCommentVNode("", true),
            (_unref(imgData).btnPrizeImg)
              ? (_openBlock(), _createElementBlock("img", {
                  key: 1,
                  src: _unref(imgUrl) + _unref(imgData).btnPrizeImg,
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_unref(jump)('/prize'))),
                  class: "link reserve-link prize",
                  alt: ""
                }, null, 8, _hoisted_3))
              : _createCommentVNode("", true),
            (_unref(imgData).btnHistoryImg)
              ? _withDirectives((_openBlock(), _createElementBlock("img", {
                  key: 2,
                  src: _unref(imgUrl) + _unref(imgData).btnHistoryImg,
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_unref(jump)('/history'))),
                  class: "link reserve-link history",
                  alt: ""
                }, null, 8, _hoisted_4)), [
                  [_vShow, _unref(isShowHistory)]
                ])
              : _createCommentVNode("", true),
            (_unref(imgData).btnMissImg)
              ? (_openBlock(), _createElementBlock("img", {
                  key: 3,
                  src: _unref(imgUrl) + _unref(imgData).btnMissImg,
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (_unref(openReserveCode)())),
                  class: "link reserve-link cjhm",
                  alt: ""
                }, null, 8, _hoisted_5))
              : _createCommentVNode("", true),
            (_unref(imgData).btnWinnerImg)
              ? _withDirectives((_openBlock(), _createElementBlock("img", {
                  key: 4,
                  src: _unref(imgUrl) + _unref(imgData).btnWinnerImg,
                  onClick: _cache[4] || (_cache[4] = ($event: any) => (_unref(jump)('/publicty'))),
                  class: "link reserve-link zjgs",
                  alt: ""
                }, null, 8, _hoisted_6)), [
                  [_vShow, _unref(isShowPublicty)]
                ])
              : _createCommentVNode("", true)
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_7, [
            _createElementVNode("img", {
              src: _unref(imgUrl) + _unref(hdgzt),
              onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_unref(showDrawRule) && _unref(showDrawRule)(...args))),
              class: "link",
              alt: ""
            }, null, 8, _hoisted_8),
            _createElementVNode("img", {
              src: _unref(imgUrl) + _unref(wdjpant),
              onClick: _cache[6] || (_cache[6] = ($event: any) => (_unref(jump)('/prize'))),
              class: "link prize",
              alt: ""
            }, null, 8, _hoisted_9),
            (_unref(imgData).btnHistoryImg)
              ? _withDirectives((_openBlock(), _createElementBlock("img", {
                  key: 0,
                  src: _unref(imgUrl) + _unref(imgData).btnHistoryImg,
                  onClick: _cache[7] || (_cache[7] = ($event: any) => (_unref(jump)('/history'))),
                  class: "link history",
                  alt: ""
                }, null, 8, _hoisted_10)), [
                  [_vShow, _unref(isShowHistory)]
                ])
              : _createCommentVNode("", true),
            (_unref(imgData).btnMissImg)
              ? (_openBlock(), _createElementBlock("img", {
                  key: 1,
                  src: _unref(imgUrl) + _unref(imgData).btnMissImg,
                  onClick: _cache[8] || (_cache[8] = ($event: any) => (_unref(openReserveCode)())),
                  class: "link cjhm",
                  alt: ""
                }, null, 8, _hoisted_11))
              : _createCommentVNode("", true),
            (_unref(imgData).btnWinnerImg)
              ? _withDirectives((_openBlock(), _createElementBlock("img", {
                  key: 2,
                  src: _unref(imgUrl) + _unref(imgData).btnWinnerImg,
                  onClick: _cache[9] || (_cache[9] = ($event: any) => (_unref(jump)('/publicty'))),
                  class: "link zjgs",
                  alt: ""
                }, null, 8, _hoisted_12)), [
                  [_vShow, _unref(isShowPublicty)]
                ])
              : _createCommentVNode("", true)
          ])),
      (_unref(activityType) == 1)
        ? (_openBlock(), _createElementBlock("img", {
            key: 2,
            src: _unref(appointmentImg),
            style: _normalizeStyle({ top: _unref(bottomHeight) + 'px' }),
            class: "appointment",
            alt: "",
            onClick: _cache[10] || (_cache[10] = 
//@ts-ignore
(...args) => (_unref(appointment) && _unref(appointment)(...args)))
          }, null, 12, _hoisted_13))
        : _createCommentVNode("", true),
      (_unref(activityType) == 2)
        ? (_openBlock(), _createElementBlock("div", {
            key: 3,
            class: "appointment cards u-flex u-ai-fs u-f-w",
            style: _normalizeStyle({ top: (40 + _unref(bottomHeight)) + 'px' }),
            onClick: _cache[11] || (_cache[11] = 
//@ts-ignore
(...args) => (_unref(draw) && _unref(draw)(...args)))
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(cardsList), (item, index) => {
              return (_openBlock(), _createElementBlock("img", {
                src: _unref(imgUrl) + item,
                key: index,
                alt: ""
              }, null, 8, _hoisted_14))
            }), 128))
          ], 4))
        : _createCommentVNode("", true),
      (_unref(activityType) == 3 && !_unref(isRolling))
        ? (_openBlock(), _createElementBlock("img", {
            key: 4,
            src: _unref(imgUrl) + _unref(wt),
            style: _normalizeStyle({ top: (_unref(bottomHeight) - 60) + 'px' }),
            class: "appointment",
            alt: "",
            onClick: _cache[12] || (_cache[12] = 
//@ts-ignore
(...args) => (_unref(rolling) && _unref(rolling)(...args)))
          }, null, 12, _hoisted_15))
        : _createCommentVNode("", true),
      (_unref(activityType) == 3 && _unref(isRolling))
        ? (_openBlock(), _createElementBlock("img", {
            key: 5,
            src: _unref(imgUrl) + _unref(tzxggift),
            style: _normalizeStyle({ top: (_unref(bottomHeight) - 95) + 'px' }),
            class: "appointment rolling",
            alt: ""
          }, null, 12, _hoisted_16))
        : _createCommentVNode("", true),
      (_unref(activityType) == 4)
        ? (_openBlock(), _createElementBlock("div", {
            key: 6,
            class: "appointment eggs u-flex u-ai-fs u-f-w",
            style: _normalizeStyle({ top: (70 + _unref(bottomHeight)) + 'px' })
          }, [
            (_openBlock(), _createElementBlock(_Fragment, null, _renderList(6, (i) => {
              return _createElementVNode("img", {
                src: _unref(imgUrl) + _unref(jdt),
                key: i,
                class: "jdt",
                alt: "",
                onClick: ($event: any) => (_unref(smash)(i))
              }, null, 8, _hoisted_17)
            }), 64)),
            _withDirectives(_createElementVNode("img", {
              src: _unref(imgUrl) + _unref(czt),
              class: "hammer",
              style: _normalizeStyle({ top: _unref(hammerTop), left: _unref(hammerLeft) }),
              alt: ""
            }, null, 12, _hoisted_18), [
              [_vShow, _unref(isShowHammer)]
            ]),
            _withDirectives(_createElementVNode("img", {
              src: _unref(imgUrl) + _unref(dst),
              class: "crack",
              style: _normalizeStyle({ top: _unref(crackTop), left: _unref(crackLeft) }),
              alt: ""
            }, null, 12, _hoisted_19), [
              [_vShow, _unref(isShowCrack)]
            ])
          ], 4))
        : _createCommentVNode("", true),
      (_unref(activityType) == 5)
        ? (_openBlock(), _createElementBlock("div", {
            key: 7,
            class: "appointment mh u-flex u-ai-fs u-f-w",
            style: _normalizeStyle({ top: (70 + _unref(bottomHeight)) + 'px' }),
            onClick: _cache[13] || (_cache[13] = 
//@ts-ignore
(...args) => (_unref(draw) && _unref(draw)(...args)))
          }, [
            (_openBlock(), _createElementBlock(_Fragment, null, _renderList(9, (i) => {
              return _createElementVNode("img", {
                src: _unref(imgUrl) + _unref(mht),
                key: i,
                class: "mht"
              }, null, 8, _hoisted_20)
            }), 64))
          ], 4))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_21, [
        _createElementVNode("img", {
          src: _unref(imgUrl) + _unref(bgTop),
          id: "bgTop",
          alt: ""
        }, null, 8, _hoisted_22),
        _createElementVNode("img", {
          src: _unref(imgUrl) + _unref(bgBottom),
          id: "bgBottom",
          class: "bg-bottom",
          alt: ""
        }, null, 8, _hoisted_23),
        _createElementVNode("div", {
          class: "surplus",
          innerHTML: _unref(surplusText),
          style: _normalizeStyle({ top: _unref(surplusTop) })
        }, null, 12, _hoisted_24),
        (_unref(imgData).rwant)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              class: "mission-btn",
              src: _unref(imgUrl) + _unref(imgData).rwant,
              onClick: _cache[14] || (_cache[14] = 
//@ts-ignore
(...args) => (_unref(openMission) && _unref(openMission)(...args))),
              style: _normalizeStyle({ width: _unref(missionWidth) }),
              alt: ""
            }, null, 12, _hoisted_25))
          : _createCommentVNode("", true)
      ]),
      _cache[16] || (_cache[16] = _createElementVNode("div", { class: "bottom" }, null, -1)),
      _withDirectives(_createElementVNode("button", {
        class: "log-btn",
        onClick: _cache[15] || (_cache[15] = 
//@ts-ignore
(...args) => (_unref(auth) && _unref(auth)(...args)))
      }, null, 512), [
        [_vShow, _unref(isShowAuth)]
      ])
    ], 4),
    _createVNode(VerifyPop, {
      ref: "verifyRef",
      onConfirm: _unref(verify)
    }, null, 8, ["onConfirm"]),
    _createVNode(RulePop, { ref: "ruleRef" }, null, 512),
    _createVNode(DrawRule, {
      ref: "drawRuleRef",
      hdgznrt: _unref(hdgznrt)
    }, null, 8, ["hdgznrt"]),
    _createVNode(Popup, {
      ref: "popRef",
      type: _unref(type),
      prizeNumber: _unref(reserveCode),
      popPic: _unref(popPic),
      popTxt: _unref(popTxt),
      onMethods: _unref(methods),
      onPrizeRead: _unref(prizeRead),
      onReserveRead: _unref(reserveRead),
      onJumpMiss: _unref(jumpMiss),
      prizeName: _unref(prizeName)
    }, null, 8, ["type", "prizeNumber", "popPic", "popTxt", "onMethods", "onPrizeRead", "onReserveRead", "onJumpMiss", "prizeName"]),
    _createVNode(finish, { ref: "finish" }, null, 512),
    _createVNode(MissionPop, {
      ref: "missionRef",
      missionList: _unref(missionList),
      rwwwcan: _unref(rwwwcan),
      rwywcan: _unref(rwywcan),
      missionHeight: _unref(missionHeight),
      missionPaddingTop: _unref(missionPaddingTop),
      onMissionClose: _unref(getUserInfo)
    }, null, 8, ["missionList", "rwwwcan", "rwywcan", "missionHeight", "missionPaddingTop", "onMissionClose"]),
    _createVNode(DrawPop, {
      ref: "drawRef",
      drawBg: _unref(drawBg),
      popTxt: _unref(drawCopywriting),
      onClose: _unref(drawClose),
      drawHeight: _unref(drawHeight),
      drawWidth: _unref(drawWidth),
      drawPadding: _unref(drawPadding),
      drawPaddingRight: _unref(drawPaddingRight),
      prizeType: _unref(prizeType)
    }, null, 8, ["drawBg", "popTxt", "onClose", "drawHeight", "drawWidth", "drawPadding", "drawPaddingRight", "prizeType"])
  ]))
}
}

})