import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, isRef as _isRef, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/close.png'
import _imports_1 from '@/assets/images/bell.png'
import _imports_2 from '@/assets/images/image-crack.png'
import _imports_3 from '@/assets/images/bless-bag.png'
import _imports_4 from '@/assets/images/open.png'
import _imports_5 from '@/assets/images/confirm.png'
import _imports_6 from '@/assets/images/go-prize.png'


const _hoisted_1 = { class: "popup" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = {
  src: _imports_1,
  class: "pop-icon"
}
const _hoisted_4 = {
  src: _imports_2,
  class: "pop-icon crack"
}
const _hoisted_5 = {
  src: _imports_3,
  class: "pop-icon bag"
}
const _hoisted_6 = { class: "title" }
const _hoisted_7 = { class: "title" }
const _hoisted_8 = { class: "title" }
const _hoisted_9 = { class: "title yuyue-title" }
const _hoisted_10 = { class: "content" }
const _hoisted_11 = { class: "content" }
const _hoisted_12 = { class: "content" }
const _hoisted_13 = { class: "content" }
const _hoisted_14 = { class: "content" }
const _hoisted_15 = { class: "prize-number" }

import router from '@/router';
import { closeToast, showLoadingToast, showToast } from 'vant';
import { getStorage } from '@/utils/local'
import { ref, onMounted, shallowRef, getCurrentInstance, nextTick, proxyRefs, useAttrs, reactive } from 'vue'


/****************组件、js等引入*************/
interface Props {
  type: any,//1提示 2遗憾 3中奖 4无明细 5抽奖号码
  popPic:string,
  popTxt:string,
  prizeNumber:string,
  prizeName:string
  //?:表示可选属性；:表示必选属性
}
//props默认值

export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  props: {
    type: { default: 4 },
    popPic: { default: "" },
    popTxt: { default: "" },
    prizeNumber: { default: '' },
    prizeName: { default: "" }
  },
  emits: ['methods','prizeRead','reserveRead','jumpMiss'],
  setup(__props: any, { expose: __expose, emit: __emit }) {

const { proxy } = (getCurrentInstance() as any)


/****************渲染dom数据*************/
let show = ref(false)
/****************渲染dom数据*************/
/***************将组件内给父组件的值暴露出去*****************/
__expose({ show })
/***************将组件内给父组件的值暴露出去*****************/
/****************参数props*************/
// const props = defineProps({})//非ts环境
const props = __props
/****************参数props*************/
/****************函数*************/
const emits = __emit
let goOpen =()=>{
    window.location.href = 'https://www.abchina.com/web2native|channel=wallet_home*menu=wallet_home*step=02*param=1110'
    show.value = false
}
let jumpPrize =()=>{
    emits("prizeRead")
    proxy.$router.push('/prize')
}
let verify = () =>{
    show.value = false
    console.log(props.type)
    if(props.type == 3){
        emits("prizeRead")
    }
    if(props.type == 5){
        emits("reserveRead")
    }
    if(props.type == 2){
        emits("jumpMiss")
    }
}
/****************函数*************/

/****************生命周期*************/
// createApp(()=>{
//     console.log("created")
// })
onMounted(async () => {
})
  /****************生命周期*************/


return (_ctx: any,_cache: any) => {
  const _component_van_popup = _resolveComponent("van-popup")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_van_popup, {
      show: _unref(show),
      "onUpdate:show": _cache[4] || (_cache[4] = ($event: any) => (_isRef(show) ? (show).value = $event : show = $event)),
      "close-on-click-overlay": false
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("img", {
            src: _imports_0,
            class: "close",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_unref(verify) && _unref(verify)(...args)))
          }),
          _withDirectives(_createElementVNode("img", _hoisted_3, null, 512), [
            [_vShow, _ctx.type == 1 || _ctx.type == 4 || _ctx.type == 5]
          ]),
          _withDirectives(_createElementVNode("img", _hoisted_4, null, 512), [
            [_vShow, _ctx.type == 2]
          ]),
          _withDirectives(_createElementVNode("img", _hoisted_5, null, 512), [
            [_vShow, _ctx.type == 3]
          ]),
          _withDirectives(_createElementVNode("div", _hoisted_6, " 提示 ", 512), [
            [_vShow, _ctx.type == 1 || _ctx.type == 4]
          ]),
          _withDirectives(_createElementVNode("div", _hoisted_7, " 很遗憾 ", 512), [
            [_vShow, _ctx.type == 2]
          ]),
          _withDirectives(_createElementVNode("div", _hoisted_8, " 运气爆棚 ", 512), [
            [_vShow, _ctx.type == 3]
          ]),
          _withDirectives(_createElementVNode("div", _hoisted_9, " 预约成功 ", 512), [
            [_vShow, _ctx.type == 5]
          ]),
          _withDirectives(_createElementVNode("div", _hoisted_10, _cache[5] || (_cache[5] = [
            _createElementVNode("p", null, "本次活动需开通", -1),
            _createElementVNode("p", null, "数字人民币账户才可参与", -1)
          ]), 512), [
            [_vShow, _ctx.type == 1]
          ]),
          _withDirectives(_createElementVNode("div", _hoisted_11, _cache[6] || (_cache[6] = [
            _createElementVNode("p", null, "暂无明细", -1),
            _createElementVNode("p", null, "快去参加活动", -1)
          ]), 512), [
            [_vShow, _ctx.type == 4]
          ]),
          _withDirectives(_createElementVNode("div", _hoisted_12, _cache[7] || (_cache[7] = [
            _createElementVNode("p", null, "您与奖品擦肩而过", -1),
            _createElementVNode("p", null, "下次再来！", -1)
          ]), 512), [
            [_vShow, _ctx.type == 2]
          ]),
          _withDirectives(_createElementVNode("div", _hoisted_13, [
            _cache[8] || (_cache[8] = _createElementVNode("p", null, "恭喜您！", -1)),
            _createElementVNode("p", null, "获得" + _toDisplayString(_ctx.prizeName), 1)
          ], 512), [
            [_vShow, _ctx.type == 3]
          ]),
          _withDirectives(_createElementVNode("div", _hoisted_14, [
            _cache[9] || (_cache[9] = _createElementVNode("p", null, "您的抽奖号码为", -1)),
            _createElementVNode("p", _hoisted_15, _toDisplayString(_ctx.prizeNumber), 1)
          ], 512), [
            [_vShow, _ctx.type == 5]
          ]),
          _withDirectives(_createElementVNode("img", {
            src: _imports_4,
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_unref(goOpen) && _unref(goOpen)(...args))),
            class: "btn"
          }, null, 512), [
            [_vShow, _ctx.type == 1]
          ]),
          _withDirectives(_createElementVNode("img", {
            src: _imports_5,
            class: "btn",
            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_unref(verify) && _unref(verify)(...args)))
          }, null, 512), [
            [_vShow, _ctx.type == 2 || _ctx.type == 4 || _ctx.type == 5]
          ]),
          _withDirectives(_createElementVNode("img", {
            src: _imports_6,
            class: "btn",
            onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_unref(jumpPrize) && _unref(jumpPrize)(...args)))
          }, null, 512), [
            [_vShow, _ctx.type == 3]
          ])
        ])
      ]),
      _: 1
    }, 8, ["show"])
  ]))
}
}

})