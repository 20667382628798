import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/poster.jpg'


const _hoisted_1 = {
  ref: "poster",
  class: "poster"
}
const _hoisted_2 = {
  class: "qrcode",
  style: {"right":"22.5px","bottom":"19px"}
}

import html2canvas from 'html2canvas';
import { ref, onMounted, computed, shallowRef, getCurrentInstance, nextTick, proxyRefs, useAttrs, reactive } from 'vue'
import QrcodeVue from 'qrcode.vue'

interface Props {
    activityTaskId: string,
    //?:表示可选属性；:表示必选属性
}

export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  props: {
    activityTaskId: { default: "" }
  },
  emits: ['getPosterUrl'],
  setup(__props: any, { emit: __emit }) {

const { proxy } = (getCurrentInstance() as any)
const emits = __emit;



let imageUrl = ref('')
const props = __props
let codeUrl = computed(() => {
  const urlParams = new URLSearchParams(window.location.search);
  return 'https://abcdc.fansiji.com/#/index?aid=' + (urlParams.get('aid') || proxy.$route.query.aid) + '&activityTaskId=' + props.activityTaskId + '&token=' + proxy.$getStorage("token");
})
const convertToImage = async () => {
  try {
    const canvas = await html2canvas(proxy.$refs.poster);
    imageUrl.value = canvas.toDataURL('image/png');
    emits('getPosterUrl', imageUrl.value);
  } catch (error) {
    console.error('转换出错:', error);
  }
}


onMounted(async () => {
  nextTick(() => {
    convertToImage()
  })
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(QrcodeVue, {
        value: _unref(codeUrl),
        size: "86"
      }, null, 8, ["value"])
    ]),
    _cache[0] || (_cache[0] = _createElementVNode("img", {
      class: "bg",
      style: {"width":"375px","height":"667px"},
      src: _imports_0,
      alt: ""
    }, null, -1))
  ], 512))
}
}

})