import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, isRef as _isRef, vModelText as _vModelText, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/phone.png'
import _imports_1 from '@/assets/images/close.png'
import _imports_2 from '@/assets/images/clear.png'
import _imports_3 from '@/assets/images/go-change.png'
import _imports_4 from '@/assets/images/confirm.png'


const _hoisted_1 = { class: "popup" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = {
  src: _imports_0,
  class: "phone",
  alt: ""
}
const _hoisted_4 = { class: "title" }
const _hoisted_5 = { class: "input-block" }
const _hoisted_6 = { class: "confirm-hint" }
const _hoisted_7 = { class: "confirm-hint warn" }
const _hoisted_8 = { class: "bottom u-flex u-ai-c u-jc-c" }

import router from '@/router';
import { closeToast, showLoadingToast, showToast } from 'vant';
import { getStorage } from '@/utils/local'
import { ref, onMounted, shallowRef, getCurrentInstance, nextTick, proxyRefs, useAttrs, reactive } from 'vue'


/****************组件、js等引入*************/
interface Props {
  mobile: string,
  //?:表示可选属性；:表示必选属性
}
//props默认值

export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  props: {
    mobile: { default: '' }
  },
  setup(__props: any, { expose: __expose }) {

const { proxy } = (getCurrentInstance() as any)


/****************渲染dom数据*************/
let show = ref(false)
let number = ref('')
let checked = ref('0')
let isChecked = ref(true)
let step = ref(1)
/****************渲染dom数据*************/
// const props = defineProps({})//非ts环境
const props = __props
/****************参数props*************/
/***************将组件内给父组件的值暴露出去*****************/
__expose({ show })
/***************将组件内给父组件的值暴露出去*****************/
/****************函数*************/
let check =()=>{
    isChecked.value = !isChecked.value
    if(isChecked.value){
        checked.value = '0'
    }
    console.log(checked.value)
}
let clear =()=>{
    number.value = ''
}
let goChange =()=>{
    step.value = 1
}
let confirm =()=>{
    if(number.value.length != 11){
        return showToast("请输入正确的手机号")
    }
    if(step.value == 1){
        return step.value = 2
    }
    proxy.$emit("confirm",number.value)
}
/****************函数*************/

/****************生命周期*************/
// createApp(()=>{
//     console.log("created")
// })
onMounted(async () => {
})
/****************生命周期*************/

return (_ctx: any,_cache: any) => {
  const _component_van_popup = _resolveComponent("van-popup")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_van_popup, {
      show: _unref(show),
      "onUpdate:show": _cache[5] || (_cache[5] = ($event: any) => (_isRef(show) ? (show).value = $event : show = $event)),
      "close-on-click-overlay": false
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _withDirectives(_createElementVNode("img", _hoisted_3, null, 512), [
            [_vShow, _unref(step) == 2]
          ]),
          _createElementVNode("img", {
            src: _imports_1,
            class: "close",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_isRef(show) //@ts-ignore
 ? show.value = false : show = false))
          }),
          _withDirectives(_createElementVNode("div", _hoisted_4, _cache[6] || (_cache[6] = [
            _createElementVNode("p", null, "请输入参与活动手机号", -1),
            _createElementVNode("p", null, "确保与农行掌银登录账户一致", -1)
          ]), 512), [
            [_vShow, _unref(step) == 1]
          ]),
          _withDirectives(_createElementVNode("div", _hoisted_5, [
            _withDirectives(_createElementVNode("input", {
              class: "number",
              type: "text",
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_isRef(number) ? (number).value = $event : number = $event))
            }, null, 512), [
              [_vModelText, _unref(number)]
            ]),
            _withDirectives(_createElementVNode("img", {
              src: _imports_2,
              onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_unref(clear) && _unref(clear)(...args))),
              class: "clear"
            }, null, 512), [
              [_vShow, _unref(number).length > 0]
            ])
          ], 512), [
            [_vShow, _unref(step) == 1]
          ]),
          _withDirectives(_createElementVNode("div", _hoisted_6, " 确认使用该手机号码参与活动？ ", 512), [
            [_vShow, _unref(step) == 2]
          ]),
          _withDirectives(_createElementVNode("div", { class: "confirm-number" }, _toDisplayString(_unref(number)), 513), [
            [_vShow, _unref(step) == 2]
          ]),
          _withDirectives(_createElementVNode("div", _hoisted_7, " 一经确认，无法修改 ", 512), [
            [_vShow, _unref(step) == 2]
          ]),
          _cache[7] || (_cache[7] = _createElementVNode("div", { class: "u-flex u-ai-c" }, [
            _createElementVNode("div", { class: "check" })
          ], -1)),
          _createElementVNode("div", _hoisted_8, [
            _withDirectives(_createElementVNode("img", {
              src: _imports_3,
              onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_unref(goChange) && _unref(goChange)(...args))),
              class: "btn",
              alt: ""
            }, null, 512), [
              [_vShow, _unref(step) == 2]
            ]),
            _createElementVNode("img", {
              src: _imports_4,
              onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_unref(confirm) && _unref(confirm)(...args))),
              class: "btn"
            })
          ])
        ])
      ]),
      _: 1
    }, 8, ["show"])
  ]))
}
}

})